
import common from './common';

const Player = common.Player

const selectors = {
	video : 'iframe.js-video-play-pause__video',
	buttonToggle : '.js-video-play-pause__button'
}

const classes = {
	buttonPaused : 'is-paused',
	buttonPlaying : 'is-playing',
}

function createPlayHandler(buttonToggle) {
	return (event) => {
		// TODO:
		// set aria and text label
		buttonToggle.classList.add(classes.buttonPlaying)
		buttonToggle.classList.remove(classes.buttonPaused)
	}
}

function createPauseHandler(buttonToggle) {
	return (event) => {
		// TODO:
		// set aria and text label
		buttonToggle.classList.add(classes.buttonPaused)
		buttonToggle.classList.remove(classes.buttonPlaying)
	}
}

function createClickHandler(buttonToggle, player) {
	return (event) => {
		const buttonToggle = event.target
		player.getPaused().then((paused) => paused ? player.play() : player.pause())
	}	
}

export const initInstance = function(el) {
	const video = el.querySelector(selectors.video)
	const buttonToggle = el.querySelector(selectors.buttonToggle)
	if (!video || !buttonToggle) return

	const player = new Player(video)
	player.on('play', createPlayHandler(buttonToggle))
	player.on('pause', createPauseHandler(buttonToggle))
	buttonToggle.addEventListener('click', createClickHandler(buttonToggle, player))
}
